import React from "react";
import Partner from "../components/partner";

import Michelin from '../images/partners/michelin.png'
import Telekom from '../images/partners/telekom.png'
import Unil from '../images/partners/unil.png'
import Agoric from '../images/partners/agoric.png'
import Swisscare from '../images/partners/swisscare.png'
import Celestia from '../images/partners/celestia.png'
import Zippsafe from '../images/partners/zippsafe.png'
import Aisb from '../images/partners/aisb.png'
import Kleber from '../images/partners/kleber.png'
import Turfgrass from '../images/partners/pannon-turfgrass.png'
import TSystems from '../images/partners/t-systems.png'
import CocaCola from '../images/partners/coca-cola.png'

class Partners extends React.Component {
    render() {
        return (
            <section className={'partners-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 pb-3'}>
                            <h2>Our clients benefit from a thoughtful and creative partnership.</h2>
                        </div>
                        <div className={'col-12'}>
                            <div className={'row mt-md-5 align-items-center'}>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Michelin'} logo={Michelin}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Telekom'} logo={Telekom}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Coca-Cola'} logo={CocaCola}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Agoric'} logo={Agoric}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Swisscare'} logo={Swisscare}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Celestia'} logo={Celestia}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Zippsafe'} logo={Zippsafe}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'American International School of Budapest'} logo={Aisb}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Kleber'} logo={Kleber}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Université de Lausanne'} logo={Unil}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Pannon Turfgrass'} logo={Turfgrass}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'T-Systems'} logo={TSystems}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Partners;
