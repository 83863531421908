import * as React from "react"
import {Link} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import Partners from "../sections/partners";

import { StaticImage } from "gatsby-plugin-image"

import Gallery from "../components/gallery";
import WorkArrow from "../components/workArrow";

const IndexPage = ({...props}) => {
  return (
    <main>
        <Helmet>
            <title>Web design, Branding, Apps | Design Studio in Budapest, Designatives</title>
            <link rel="canonical" href={'https://designatives.com/'} />

            <meta name="description" content="We are a design studio in Budapest, Hungary. We do branding, web design and development, UI/UX design, mobile app development and video ads."/>
            <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8"/>
            <meta name="format-detection" content="telephone=no"/>
            <meta name="theme-color" content="#fff"/>

            <link rel="schema.dcterms" href="http://purl.org/dc/terms/"/>
            <meta name="DC.coverage" content="Hungary"/>
            <meta name="DC.description" content="We are a design studio in Budapest, Hungary. We do branding, web design and development, UI/UX design, mobile app development and video ads."/>
            <meta name="DC.format" content="text/html"/>
            <meta name="DC.identifier" content="http://www.designatives.com"/>
            <meta name="DC.publisher" content="Designatives - Web design, Branding, Apps | Design studio in Budapest, Hungary"/>
            <meta name="DC.title" content="Designatives - Web design, Branding, Apps | Design studio in Budapest, Hungary"/>
            <meta name="DC.type" content="Text"/>

            <meta property="fb:app_id" content="355084464688689"/>
            <meta property="og:url" content="https://designatives.com/"/>
            <meta property="og:type" content=""/>
            <meta property="og:title" content="Designatives"/>
            <meta property="og:site_name" content="Designatives"/>
            <meta property="og:description" content="We are a design studio in Budapest, Hungary. We do branding, web design and development, UI/UX design, mobile app development and video ads."/>
            <meta property="og:site_name" content="Designatives - Web design, Branding, Apps | Design studio in Budapest, Hungary"/>
            <meta property="og:image" content="http://www.designatives.com/og-image.png"/>

            <script type="text/javascript" async="" src="https://www.statcounter.com/counter/recorder.js"/>
            <script async="" src="https://www.googletagmanager.com/gtm.js?id=GTM-KRRNTWK"/>
        </Helmet>

        <Header/>

        <Layout>
            <section className={'hero-section'}>
                <div className={'container'}>
                    <h1 className={'level1'}>
                        Hi! We are Designatives. <br/>
                        A full service design studio.
                    </h1>

                    <a href="https://calendly.com/designatives/30-minute-meeting" target={'_blank'} rel={'noreferrer'}>
                        <div className={'btn'}>
                            <WorkArrow/>
                            Book a meeting
                        </div>
                    </a>
                </div>
            </section>

            <section className="work-section">
                <div className="container">
                    <div className="row">
                        <div className={'col-12'}>
                            <Gallery/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'services-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-6 col-xl-3 order-lg-2'}>
                            <div className={'image-container float-lg-end'}>
                                <StaticImage
                                    src="../images/services/branding.png"
                                    alt="Branding"
                                    placeholder="tracedSVG"
                                    layout="constrained"
                                    imgClassName="image"
                                    objectFit={'contain'}
                                    width={592}
                                    height={530}
                                    quality={100}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-lg-6 col-xl-9 pt-3 pt-lg-0'}>
                            <div className={'tag'}>Branding</div>

                            <h2 className={'level3'}>Branding concepts enchanted by design</h2>
                            <p>Branding can make or break a company, a product or a service; that's why we take it very seriously and spend the necessary time to make sure the designs we create are exceptional.</p>

                            <Link to={'/services/?category=branding'}>
                                <div className={'btn mt-3'}>
                                    <WorkArrow/>
                                    Learn more about branding
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>

            <section className={'services-section web-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-6'}>
                            <div className={'image-container left'}>
                                <StaticImage
                                    src="../images/services/web.png"
                                    alt="Web"
                                    placeholder="tracedSVG"
                                    layout="constrained"
                                    imgClassName="image"
                                    objectFit={'contain'}
                                    width={659}
                                    height={451}
                                    quality={100}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-lg-6 pt-3 pt-lg-0'}>
                            <div className={'tag'}>Web</div>

                            <h2 className={'level3'}>Beautiful websites built for performance</h2>
                            <p>Web design and development always go hand-in-hand; good design must be followed by great development to make the final product perfect. A great website is easy to use, bug free and looks stunning.</p>

                            <Link to={'/services/?category=web'}>
                                <div className={'btn mt-3'}>
                                    <WorkArrow/>
                                    Learn more about web
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>

            <section className={'services-section app-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-5 order-lg-2'}>
                            <div className={'image-container right'}>
                                <StaticImage
                                    src="../images/services/app.png"
                                    alt="Apps"
                                    placeholder="tracedSVG"
                                    layout="constrained"
                                    imgClassName="image"
                                    objectFit={'contain'}
                                    width={656}
                                    height={628}
                                    quality={100}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-lg-7 pt-3 pt-lg-0'}>
                            <div className={'tag'}>Apps</div>

                            <h2 className={'level3'}>Apps developed to perfection</h2>
                            <p>Everyone and their dog has an idea for an app, making them a reality is the tricky part. The apps that we build are stable, have well thought-out user interface designs and a scalable backend.</p>


                            <Link to={'/services/?category=apps'}>
                                <div className={'btn mt-3'}>
                                    <WorkArrow/>
                                    Learn more about apps
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>

            <section className={'services-section video-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 col-lg-6'}>
                            <div className={'image-container left'}>
                                <StaticImage
                                    src="../images/services/video.png"
                                    alt="Video"
                                    placeholder="tracedSVG"
                                    layout="constrained"
                                    imgClassName="image"
                                    objectFit={'contain'}
                                    width={643}
                                    height={584}
                                    quality={100}
                                />
                            </div>
                        </div>
                        <div className={'col-12 col-lg-6 pt-3 pt-lg-0'}>
                            <div className={'tag'}>Video</div>

                            <h2 className={'level3'}>Animated and filmed video production</h2>
                            <p>The substance and essence of a good communication concept needs to be moulded together with an interesting story. A great storyline, captivating shots and coherent music make all the difference in post-production.</p>

                            <Link to={'/services/?category=video'}>
                                <div className={'btn mt-3'}>
                                    <WorkArrow/>
                                    Learn more about video
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </section>

            <Partners/>

        </Layout>
        <Footer/>
    </main>
  )
}

export default IndexPage
