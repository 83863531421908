import React from "react";
import {Link} from "gatsby";

import billionairesRow from "../works/BillionairesRow/main.png";
import celestia from "../works/Celestia/main.png";
import biocow from "../works/Biocow/main.png";
import glassview from "../works/Glassview/hero.png";
import welcomech from "../works/WelcomeCH/main.png";
import sg1 from "../works/SG-1/main.png";
import aisb1 from "../works/Aisb/aisb-main-1.png";
import aisb2 from "../works/Aisb/aisb-main-2.png";
import aisb3 from "../works/Aisb/aisb-main-3.png";
import aisb4 from "../works/Aisb/aisb-main-4.png";
import aisb5 from "../works/Aisb/aisb-main-5.png";

import WorkArrow from "./workArrow";

import InterProtocolMp4 from "../works/InterProtocol/video/main.mp4";
import InterProtocol from "../works/InterProtocol/video/main.webm";
import JunoMp4 from "../works/Juno/video/main.mp4";
import Juno from "../works/Juno/video/main.webm";
import AbleMp4 from "../works/Able/video/main.mp4";
import Able from "../works/Able/video/main.webm";
import TurfgrassMp4 from "../works/Turfgrass/video/logoAnim.mp4";
import Turfgrass from "../works/Turfgrass/video/logoAnim.webm";
import AgoricMp4 from "../works/Agoric/video/main.mp4";
import Agoric from "../works/Agoric/video/main.webm";

class Gallery extends React.Component {
    render() {
        return (
            <div className={'dn-grid'}>
                <div className={'grid-p50 item item-black'}>
                    <Link to={'works/billionaires-row'}>
                        <div className={'image'}>
                            <img src={billionairesRow} alt="Billionaires Row"/>
                            <div className={'title'}>
                                Billionaires Row
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-p50 item item-right item-black'}>
                    <Link to={'works/sg-1'}>
                        <div className={'image'}>
                            <img src={sg1} alt="SG 1"/>
                            <div className={'title'}>
                                SG 1
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-right'}>
                    <Link to={'works/celestia'}>
                        <div className={'image'}>
                            <img src={celestia} alt="Celestia"/>
                            <div className={'title'}>
                                Celestia
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={'grid-c33 item item-black'}>
                    <Link to={'works/juno'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={JunoMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Juno} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Juno Network
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-right item-black'}>
                    <Link to={'works/able'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={AbleMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Able} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Able
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <Link to={'works/biocow'}>
                        <div className={'image'}>
                            <img src={biocow} alt="Able"/>
                            <div className={'title'}>
                                Biocow
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c66 item item-right'}>
                    <Link to={'works/glassview'}>
                        <div className={'image'}>
                            <img src={glassview} alt="Glassview"/>
                            <div className={'title'}>
                                Glassview
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <Link to={'works/aisb'}>
                        <div className={'image'}>
                            <div className={'image-slider'}>
                                <ul>
                                    <li>
                                        <div className={'slide'}>
                                            <img src={aisb1} alt="Aisb"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'slide'}>
                                            <img src={aisb2} alt="Aisb"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'slide'}>
                                            <img src={aisb3} alt="Aisb"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'slide'}>
                                            <img src={aisb4} alt="Aisb"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'slide'}>
                                            <img src={aisb5} alt="Aisb"/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className={'title'}>
                                AISB
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>


                <div className={'grid-c33 item item-right'}>
                    <Link to={'works/inter-protocol'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={InterProtocolMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={InterProtocol} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Inter Protocol
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-black'}>
                    <Link to={'works/pannon-turfgrass'}>
                        <div className={'image'}>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={TurfgrassMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Turfgrass} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Turfgrass
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-right item-black'}>
                    <Link to={'works/welcomech'}>
                        <div className={'image'}>
                            <img src={welcomech} alt="Welcome CH"/>
                            <div className={'title'}>
                                Welcome CH
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>


                <div className={'grid-c66 item'}>
                    <Link to={'works/agoric'}>
                        <div className={'image'}>
                            <div className={'overlay'}></div>
                            <video id={'hero-video'} autoPlay={true} preload={'none'} loop={true} playsInline={true} muted={true}>
                                <source src={AgoricMp4} type='video/mp4;codecs="hvc1"'/>
                                <source src={Agoric} type="video/webm" />
                            </video>
                            <div className={'title'}>
                                Agoric
                                <WorkArrow/>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className={'grid-c33 item item-right'}>
                    <Link to={'works/'}>
                        <div className={'image'}>
                            <button type={'button'} className={'btn mt-3'}>
                                <WorkArrow/>
                                All our work
                            </button>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Gallery;
